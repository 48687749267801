import React, { useEffect, useRef } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import bordertop from "../assets/bordertop.png";
import borderbottom from "../assets/borderbottom.png";
import logo from '../assets/logo.svg';
import footer from '../assets/footer.png';
import tg from '../assets/tg.svg';
import x from '../assets/x.svg';
import discord from '../assets/discord.svg';
import tiktok from '../assets/tiktok.svg';
import instagram from '../assets/instagram.svg';

gsap.registerPlugin(ScrollTrigger);

const Footer: React.FC = () => {
    const sectionRef = useRef<HTMLDivElement>(null);
    const logoRef = useRef<HTMLImageElement>(null);
    const linksRef = useRef<HTMLUListElement>(null);
    const socialIconsRef = useRef<HTMLDivElement>(null);
    const footerTextRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const sectionElement = sectionRef.current;
        if (!sectionElement) return;

        // Animate logo
        gsap.fromTo(
            logoRef.current,
            { opacity: 0, y: -20 },
            {
                opacity: 1,
                y: 0,
                duration: 1,
                ease: 'power3.out',
                scrollTrigger: {
                    trigger: sectionElement,
                    start: 'top 80%',
                },
            }
        );

        // Animate links
        if (linksRef.current) {
            gsap.fromTo(
                Array.from(linksRef.current.children),
                { opacity: 0, y: 20 },
                {
                    opacity: 1,
                    y: 0,
                    duration: 0.8,
                    stagger: 0.2,
                    ease: 'power3.out',
                    scrollTrigger: {
                        trigger: sectionElement,
                        start: 'top 80%',
                    },
                }
            );
        }

        // Animate social icons
        if (socialIconsRef.current) {
            gsap.fromTo(
                Array.from(socialIconsRef.current.children),
                { opacity: 0, scale: 0.9 },
                {
                    opacity: 1,
                    scale: 1,
                    duration: 0.8,
                    stagger: 0.2,
                    ease: 'power3.out',
                    scrollTrigger: {
                        trigger: sectionElement,
                        start: 'top 80%',
                    },
                }
            );
        }

        // Animate footer text
        gsap.fromTo(
            footerTextRef.current,
            { opacity: 0, y: 20 },
            {
                opacity: 1,
                y: 0,
                duration: 1,
                delay: 0.5,
                ease: 'power3.out',
                scrollTrigger: {
                    trigger: sectionElement,
                    start: 'top 80%',
                },
            }
        );
    }, []);

    return (
        <footer className="relative" ref={sectionRef}>
            <div
                style={{
                    background: 'radial-gradient(circle, rgba(98,60,149,0.499) 0%, rgba(98,60,149,0.502) 50%, rgba(98,60,149,0) 100%)',
                }}
                className="h-full opacity-30 z-10 w-full absolute"
            ></div>
            <div className="relative mt-[-0.3%]">
                <img className="w-screen top-0" src={bordertop} alt="Top Border" />
            </div>
            <div className="container relative z-40 mx-auto px-4 flex flex-col md:flex-row items-center">
                {/* Left Section */}
                <div className="w-full">
                    <div className="mb-6 md:mb-0">
                        <div className="flex items-center mb-2">
                            <img className="h-8" src={logo} alt="Logo" ref={logoRef} />
                        </div>
                        <p className="text-gray-600 mt-4">
                            Empowering your journey to financial freedom with seamless on-chain lending
                        </p>
                    </div>
                    {/* Center Section */}
                    <div className="my-6 md:mb-0">
                        <ul className="flex flex-col md:flex-row md:space-x-6" ref={linksRef}>
                            {[
                                { id: "features", label: "Features" },
                                { id: "about", label: "About", url: "https://docs.kariru.xyz/" },
                                { id: "howitwork", label: "How To Kariru" },
                                { id: "token", label: "$KRU" },
                            ].map((item, index) => (
                                <li
                                    key={index}
                                    className="text-gray-700 hover:text-purple-600 cursor-pointer"
                                >
                                    <a
                                        href={item.url || `#${item.id}`} // Use external URL if provided
                                        onClick={(e) => {
                                            if (!item.url) {
                                                e.preventDefault();
                                                const section = document.getElementById(item.id);
                                                section?.scrollIntoView({ behavior: 'smooth' });
                                            }
                                        }}
                                        target={item.url ? "_blank" : "_self"} // Open external links in a new tab
                                        rel={item.url ? "noopener noreferrer" : undefined} // Secure external links
                                        className="text-gray-700 hover:text-purple-600"
                                    >
                                        {item.label}
                                    </a>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>

                <div className="mx-auto w-full">
                    <img className="w-1/3 mx-auto" src={footer} alt="Footer Decoration" />
                </div>

                {/* Right Section */}
                <div
                    className="flex flex-col md:flex-row items-center md:justify-end"
                    ref={socialIconsRef}
                >
                    <div className="flex space-x-4 my-4 md:mb-0">
                        {[tg, x, discord, tiktok, instagram].map((src, index) => (
                            <span
                                key={index}
                                className="text-2xl cursor-pointer hover:text-purple-600"
                            >
                                <img style={{ filter: 'brightness(9) invert(1)' }} className="h-20 w-20 max-md:h-8 max-md:w-8" src={src} alt="Social Icon" />
                            </span>
                        ))}
                    </div>
                </div>
            </div>
            <div className="relative mb-[-1%]">
                <img className="w-screen" src={borderbottom} alt="Bottom Border" />
            </div>
            <div className="container px-4 mx-auto">
                <div
                    className="flex flex-row flex-wrap justify-between py-4 items-center md:text-right mt-4 md:mt-0"
                    ref={footerTextRef}
                >
                    <p className="text-sm text-gray-600">© 2024 Kariru. All Rights reserved.</p>
                    <div className="flex space-x-4 mt-2">
                        <a href="#" className="text-sm text-gray-600 hover:text-purple-600">
                            Terms of use
                        </a>
                        <a href="#" className="text-sm text-gray-600 hover:text-purple-600">
                            Privacy Policy
                        </a>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
