import React, { useEffect, useRef } from 'react';
import { gsap } from 'gsap';
import sec1 from '../assets/sec1.mp4';

const HeroSection: React.FC = () => {
    const sectionRef = useRef<HTMLElement>(null);
    const headingRef = useRef<HTMLHeadingElement>(null);
    const subheadingRef = useRef<HTMLHeadingElement>(null);

    useEffect(() => {
        const sectionElement = sectionRef.current;

        if (!sectionElement) return;

        // Intersection Observer to trigger animations
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    // Animate the heading and subheading
                    gsap.fromTo(
                        headingRef.current,
                        { opacity: 0, y: -50 },
                        { opacity: 1, y: 0, duration: 1, ease: 'power3.out' }
                    );

                    gsap.fromTo(
                        subheadingRef.current,
                        { opacity: 0, y: 50 },
                        { opacity: 1, y: 0, duration: 1, delay: 0.5, ease: 'power3.out' }
                    );

                    observer.unobserve(sectionElement); // Stop observing once animation is triggered
                }
            },
            {
                threshold: 0.3, // Trigger when 30% of the section is visible
            }
        );

        observer.observe(sectionElement);

        return () => observer.disconnect(); // Cleanup observer
    }, []);

    return (
        <section
            ref={sectionRef}
            className="hero-section h-screen relative flex px-4"
        >
            <div
                style={{
                    background:
                        'radial-gradient(circle, rgba(98,60,149,0.499) 0%, rgba(98,60,149,0.502) 50%, rgba(98,60,149,0) 100%)',
                    WebkitMaskImage:
                        '-webkit-gradient(linear, left bottom, left top, from(rgba(0, 0, 0, 1)), to(rgba(0, 0, 0, 0)))',
                    WebkitMaskRepeat: 'no-repeat',
                }}
                className="h-full opacity-30 z-10 w-full absolute"
            ></div>

            {/* Background Video */}
            <video
                autoPlay
                loop
                muted
                playsInline
                className="absolute top-0 w-6/12 max-lg:w-full right-0 left-0 mx-auto h-full"
            >
                <source src={sec1} type="video/mp4" />
                Your browser does not support the video tag.
            </video>

            {/* Left Heading */}
            {/*<div className="absolute max-lg:top-[10%] top-[40%] left-[5%]">*/}
            {/*    <h1*/}
            {/*        ref={headingRef}*/}
            {/*        className="text-4xl md:text-6xl lg:text-6xl xl:text-8xl font-bold text-transparent bg-clip-text bg-gradient-to-b from-purple-100/50 to-purple-50/0"*/}
            {/*    >*/}
            {/*        KARIRU*/}
            {/*    </h1>*/}
            {/*</div>*/}

            {/* Right Heading */}
            {/*<div className="absolute top-[40%] max-lg:top-[80%] right-8">*/}
            {/*    <h1*/}
            {/*        ref={subheadingRef}*/}
            {/*        className="text-4xl md:text-6xl lg:text-6xl xl:text-8xl font-bold text-transparent bg-clip-text bg-gradient-to-b from-purple-100/50 to-purple-50/0"*/}
            {/*    >*/}
            {/*        RETURNS*/}
            {/*    </h1>*/}
            {/*</div>*/}
        </section>
    );
};

export default HeroSection;
