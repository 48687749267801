import React, { useEffect, useRef } from 'react';
import { gsap } from 'gsap';
import diagram from '../assets/diagram.png'

const KariruAdvantageSection: React.FC = () => {
    const stepRefs = useRef<HTMLDivElement[]>([]);

    useEffect(() => {
        // Animate each step to appear one by one with GSAP
        stepRefs.current.forEach((step, index) => {
            gsap.fromTo(
                step,
                { opacity: 0, y: 20 },
                { opacity: 1, y: 0, duration: 0.5, delay: index * 0.3, ease: 'power3.out' }
            );
        });
    }, []);

    return (
        <section className="relative ">
            <div
                style={{background: 'radial-gradient(circle, rgba(98,60,149,0.499) 0%, rgba(98,60,149,0.502) 50%, rgba(98,60,149,0) 100%)',}}
                className="h-full   opacity-30 z-10 w-full absolute"></div>
            <div className="container mx-auto  pb-32 px-4">
                <h2 className="text-3xl md:text-4xl font-bold text-center mb-16">The Kariru Advantage</h2>
                <img src={diagram}/>
            </div>
        </section>
    );
};

export default KariruAdvantageSection;
