import React, { useEffect, useRef } from 'react';
import { gsap } from 'gsap';
import sec3 from '../assets/3.mp4';
import { ReactComponent as XIcon } from '../assets/x.svg';
import { ReactComponent as SaleIcon } from '../assets/sale.svg';
import { ReactComponent as UniswapIcon } from '../assets/uniswap.svg';

const IntroductionSection: React.FC = () => {
    const sectionRef = useRef<HTMLElement>(null);
    const textRef = useRef<HTMLDivElement>(null);
    const imageRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const sectionElement = sectionRef.current;

        if (!sectionElement) return;

        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    // Animate text and image when the section is visible
                    gsap.fromTo(
                        textRef.current,
                        { opacity: 0, x: -50 },
                        { opacity: 1, x: 0, duration: 1, ease: 'power3.out' }
                    );

                    gsap.fromTo(
                        imageRef.current,
                        { opacity: 0, x: 50 },
                        { opacity: 1, x: 0, duration: 1, delay: 0.5, ease: 'power3.out' }
                    );

                    observer.unobserve(sectionElement); // Stop observing after animation is triggered
                }
            },
            {
                threshold: 0.3, // Trigger animation when 30% of the section is visible
            }
        );

        observer.observe(sectionElement);

        return () => observer.disconnect(); // Cleanup observer
    }, []);

    return (
        <section
            ref={sectionRef}
            className="bg-white relative"
            id="about"
        >
            <div
                style={{
                    background:
                        'radial-gradient(circle, rgba(98,60,149,0.499) 0%, rgba(98,60,149,0.502) 50%, rgba(98,60,149,0) 100%)',
                }}
                className="h-full opacity-30 z-10 w-full absolute"
            ></div>
            <div className="container max-lg:py-20 py-60 relative mx-auto flex flex-col md:flex-row items-center px-4">
                {/* Text Section */}
                <div ref={textRef} className="md:w-8/12 z-20 mb-8 md:mb-0">
                    <h2 className="text-4xl md:text-6xl lg:text-8xl xl:text-9xl pb-6 font-bold text-transparent bg-clip-text bg-gradient-to-b from-purple-300 to-purple-500/0">
                        Kariru
                    </h2>

                    <div>
                        {/* Japanese Meaning */}
                        <p className="text-lg font-title text-gray-700">
                            <strong>借りる (Japanese)</strong>
                        </p>
                        <p className="text-sm italic text-gray-500">"to borrow; to have a loan"</p>
                    </div>

                    {/* Description */}
                    <div className="mt-4">
                        <p className="text-base font-body text-gray-800">
                            Kariru is an on-chain lending platform inspired by Japanese roots. Using Uniswap V3, it adjusts interest
                            rates dynamically, letting borrowers secure ETH loans with diverse collateral. Kariru also pays real ETH
                            to token holders and liquidity providers without staking, redefining simplicity in DeFi.
                        </p>
                    </div>

                    <div className="flex space-x-12 pt-14">
                        <a href="#" className="text-purple-600 text-3xl">
                            <XIcon className="w-12 h-12 text-purple-600 fill-current" />
                        </a>
                        <a href="#" className="text-purple-600 text-3xl">
                            <SaleIcon className=" h-12 text-purple-600 fill-current" />
                        </a>

                    </div>
                </div>

                {/* Image Section */}
                <div
                    ref={imageRef}
                    className="max-lg:w-screen lg:w-8/12 max-lg:relative absolute max-lg:right-0 right-[-30%] flex justify-center"
                >
                    <video
                        autoPlay
                        loop
                        muted
                        playsInline
                        preload="auto"
                        className="rounded-lg"
                    >
                        <source src={sec3} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                </div>
            </div>
        </section>
    );
};

export default IntroductionSection;
