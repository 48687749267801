import React, { useEffect, useRef } from 'react';
import { gsap } from 'gsap';
import sec4 from '../assets/4.mp4';
import bordertop from '../assets/bordertop.png';
import borderbottom from '../assets/borderbottom.png';

const HowItWorksSection: React.FC = () => {
    const sectionRef = useRef<HTMLDivElement>(null);
    const textRefs = useRef<HTMLDivElement[]>([]);

    useEffect(() => {
        const sectionElement = sectionRef.current;

        if (!sectionElement) return;

        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    // Animate text blocks one by one
                    textRefs.current.forEach((text, index) => {
                        gsap.fromTo(
                            text,
                            { opacity: 0, y: 50 },
                            { opacity: 1, y: 0, duration: 0.8, delay: index * 0.3, ease: 'power3.out' }
                        );
                    });

                    observer.unobserve(sectionElement); // Stop observing after animation
                }
            },
            {
                threshold: 0.3, // Trigger animation when 30% of the section is visible
            }
        );

        observer.observe(sectionElement);

        return () => observer.disconnect(); // Cleanup observer
    }, []);

    return (
        <section
            ref={sectionRef}
            className="pb-12 relative overflow-x-hidden"
            id="howitwork"
        >
            <div
                style={{
                    background:
                        'radial-gradient(circle, rgba(98,60,149,0.499) 0%, rgba(98,60,149,0.502) 50%, rgba(98,60,149,0) 100%)',
                }}
                className="h-full opacity-30 z-10 w-full absolute"
            ></div>
            <div className="relative">
                <img className="w-screen top-0" src={bordertop} alt="Top Border" />
            </div>

            <div ref={sectionRef} className="mx-auto px-3">
                {/* Steps */}
                <div className="container mx-auto flex flex-col md:flex-row justify-between my-2">
                    <div
                        ref={(el) => (textRefs.current[0] = el!)}
                        className="flex-1 text-start max-lg:text-center content-center md:pr-8 max-lg:mb-8 md:mb-0"
                    >
                        <h2 className="text-3xl md:text-4xl font-bold">How To Kariru</h2>
                    </div>
                    <div
                        ref={(el) => (textRefs.current[1] = el!)}
                        className="flex-1 text-start max-lg:text-center md:border-r md:border-gray-300 md:pr-8 max-lg:mb-8 mb-4 md:mb-0"
                    >
                        <h3 className="text-xl font-semibold">Deposit Collateral</h3>
                        <p className="text-gray-600">
                            Use any liquid Uniswap V3-supported asset as collateral to access liquidity.
                        </p>
                    </div>
                    <div
                        ref={(el) => (textRefs.current[2] = el!)}
                        className="flex-1 text-start max-lg:text-center md:border-r md:border-gray-300 md:px-8 max-lg:mb-8 mb-4 md:mb-0"
                    >
                        <h3 className="text-xl font-semibold">Borrow ETH</h3>
                        <p className="text-gray-600">
                            Receive ETH to your wallet, preserving your asset positions for future growth.
                        </p>
                    </div>
                    <div
                        ref={(el) => (textRefs.current[3] = el!)}
                        className="flex-1 text-start max-lg:text-center md:pl-8 max-lg:mb-8"
                    >
                        <h3 className="text-xl font-semibold">Repay at Your Convenience</h3>
                        <p className="text-gray-600">
                            Repay on your schedule, with no hidden fees or penalties.
                        </p>
                    </div>
                </div>
                <div className="relative mb-[-1%]">
                    <img className="w-screen bottom-0-0" src={borderbottom} alt="Bottom Border" />
                </div>
                <div className="relative mb-[-6.5%]">
                    <img className="w-screen bottom-0-0" src={bordertop} alt="Top Border" />
                </div>

                {/* Adaptive Collateral and Advantage Section */}
                <div className="container mx-auto flex flex-col md:flex-row items-center">
                    <div
                        ref={(el) => (textRefs.current[4] = el!)}
                        className="flex-1 py-14"
                    >
                        {/*<h3 className="text-lg font-semibold mb-2">*/}
                        {/*    <span className="text-purple-600">| </span> Adaptive Collateral*/}
                        {/*</h3>*/}
                        <h2 className="text-3xl md:text-4xl font-bold mb-2">The Kariru Advantage</h2>
                        <p className="text-gray-600">Built for Lasting Value</p>
                    </div>
                    <div className="flex justify-center">
                        <video
                            autoPlay
                            loop
                            muted
                            playsInline
                            preload="auto"
                            className="w-3/4"
                        >
                            <source src={sec4} type="video/mp4" />
                            Your browser does not support the video tag.
                        </video>
                    </div>
                </div>
            </div>
            <div className="relative mt-[-6.1%]">
                <img className="w-screen" src={borderbottom} alt="Bottom Border" />
            </div>
        </section>
    );
};

export default HowItWorksSection;
