import React, { useEffect, useRef } from 'react';
import { gsap } from 'gsap';
import sec2 from '../assets/2.mp4';
import box1 from '../assets/box1.png';
import box2 from '../assets/box2.png';
import box3 from '../assets/box3.png';
import box4 from '../assets/box4.png';

const CorePowersSection: React.FC = () => {
    const sectionRef = useRef<HTMLElement>(null);
    const cardsRef = useRef<HTMLDivElement[]>([]);

    useEffect(() => {
        const sectionElement = sectionRef.current;

        if (!sectionElement) return;

        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    // Animate each card with GSAP
                    cardsRef.current.forEach((card, index) => {
                        gsap.fromTo(
                            card,
                            { opacity: 0, y: 50 },
                            {
                                opacity: 1,
                                y: 0,
                                duration: 0.8,
                                delay: index * 0.3,
                                ease: 'power3.out',
                            }
                        );
                    });

                    observer.unobserve(sectionElement); // Stop observing once animation is triggered
                }
            },
            {
                threshold: 0.3, // Trigger when 30% of the section is visible
            }
        );

        observer.observe(sectionElement);

        return () => observer.disconnect(); // Cleanup observer
    }, []);

    return (
        <section ref={sectionRef} className="relative" id="features">
            <div
                style={{background: 'radial-gradient(circle, rgba(98,60,149,0.499) 0%, rgba(98,60,149,0.502) 50%, rgba(98,60,149,0) 100%)',}}
                className="h-full   opacity-30 z-10 w-full absolute"></div>
            <div className="container mx-auto relative z-20 px-4">
                <h2 className="text-3xl md:text-4xl font-bold text-center pt-16 mb-16">
                    Core Powers of Kariru
                </h2>
                <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
                    {/* Large Box on the Left */}
                    <div  ref={(el) => (cardsRef.current[0] = el!)}
                        className="relative h-[36rem] md:col-span-2 md:row-span-3   rounded-3xl bg-gradient-to-t from-transparent to-purple-300">
                        <img className="absolute top-0 left-0 w-full h-full object-cover rounded-3xl" src={box1}/>
                        <div className="relative h-full flex flex-col  items-start justify-end z-10 p-6">
                            <h3 className="text-lg font-bold text-gray-900">Dynamic APRs</h3>
                            <p className="text-sm text-gray-600">
                                Uniswap V3-powered APRs that adjust in real-time, maximizing your returns with minimal
                                effort.
                            </p>
                        </div>
                        <div style={{
                            WebkitMaskImage:
                                '-webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 1)), to(rgba(0, 0, 0, 0)))',
                            WebkitMaskRepeat: 'no-repeat',
                        }} className="absolute inset-0 bg-white/30  border-2 border-purple-300 border-opacity-50 rounded-3xl"></div>
                    </div>

                    {/* Top Right Box */}
                    <div ref={(el) => (cardsRef.current[1] = el!)}
                        className="relative  h-[18rem] md:col-span-1  rounded-3xl bg-gradient-to-t from-transparent to-white-300">

                        <img className="absolute top-0 left-0 w-full h-full object-cover rounded-lg" src={box2}/>
                        <div className="relative h-full flex flex-col items-start justify-end z-10 p-6">
                            <h3 className="text-lg font-bold text-gray-900">Transparent Protocol Fees</h3>
                            <p className="text-sm text-gray-600">
                                Low, predictable fees designed to foster platform growth and user profitability.
                            </p>
                        </div>
                        <div style={{
                            WebkitMaskImage:
                                '-webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 1)), to(rgba(0, 0, 0, 0)))',
                            WebkitMaskRepeat: 'no-repeat',
                        }} className="absolute inset-0 bg-white/30  border-2 border-purple-300 border-opacity-50 rounded-3xl"></div>
                    </div>

                    {/* Middle Right Box */}
                    <div ref={(el) => (cardsRef.current[2] = el!)}
                        className="relative h-[18rem] md:col-span-1  rounded-3xl bg-gradient-to-t from-transparent to-purple-300">

                        <img className="absolute top-0 left-0 w-full h-full object-cover rounded-3xl" src={box3}/>
                        <div className="relative h-full flex flex-col items-start justify-end z-10 p-6">
                            <h3 className="text-lg font-bold text-gray-900">Flexible Collateral Options</h3>
                            <p className="text-sm text-gray-600">
                                Support for a wide range of assets, enabling you to leverage diverse collateral for
                                loans.
                            </p>
                        </div>
                        <div style={{
                            WebkitMaskImage:
                                '-webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 1)), to(rgba(0, 0, 0, 0)))',
                            WebkitMaskRepeat: 'no-repeat',
                        }} className="absolute inset-0 bg-white/30  border-2 border-purple-300 border-opacity-50 rounded-3xl"></div>
                    </div>

                    {/* Bottom Right Box */}
                    <div ref={(el) => (cardsRef.current[3] = el!)}
                        className="relative  h-[16rem] md:col-span-2  rounded-3xl bg-gradient-to-t from-transparent to-white-300">

                        <img className="absolute top-0 left-0 w-full h-full object-cover rounded-lg" src={box4}/>
                        <div className="relative h-full flex flex-col items-start justify-end z-10 p-6">
                            <h3 className="text-lg font-bold text-gray-900">Effortless Access</h3>
                            <p className="text-sm text-gray-600">
                                Seamlessly access diverse financial tools with unparalleled simplicity and transparency.
                            </p>
                        </div>
                        <div style={{
                            WebkitMaskImage:
                                '-webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 1)), to(rgba(0, 0, 0, 0)))',
                            WebkitMaskRepeat: 'no-repeat',
                        }}
                             className="absolute bg-mask-gradient inset-0 bg-white/30  border-2 border-purple-300 border-opacity-50 rounded-3xl"></div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default CorePowersSection;
