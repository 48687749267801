import React, { useEffect, useRef } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import color2 from '../assets/color2.svg';
import tg from '../assets/tg3.svg';
import x from '../assets/x.svg';
import discord from '../assets/discord.svg';
import tiktok from '../assets/tiktok.svg';
import instagram from '../assets/instagram.svg';
import tgillustration from '../assets/tgillustrator.svg';
import tg2 from '../assets/tg2.svg';

gsap.registerPlugin(ScrollTrigger);

const JoinCommunitySection: React.FC = () => {
    const sectionRef = useRef<HTMLDivElement>(null);
    const headingRef = useRef<HTMLHeadingElement>(null);
    const buttonRef = useRef<HTMLButtonElement>(null);
    const iconsRef = useRef<HTMLDivElement>(null);
    const imagesRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const sectionElement = sectionRef.current;
        if (!sectionElement) return;

        // Animate heading
        gsap.fromTo(
            headingRef.current,
            { opacity: 0, y: -50 },
            {
                opacity: 1,
                y: 0,
                duration: 1,
                ease: 'power3.out',
                scrollTrigger: {
                    trigger: sectionElement,
                    start: 'top 80%',
                },
            }
        );

        // Animate button
        gsap.fromTo(
            buttonRef.current,
            { opacity: 0, y: 50 },
            {
                opacity: 1,
                y: 0,
                duration: 1,
                delay: 0.3,
                ease: 'power3.out',
                scrollTrigger: {
                    trigger: sectionElement,
                    start: 'top 80%',
                },
            }
        );

        // Animate social icons
        if (iconsRef.current) {
            gsap.fromTo(
                Array.from(iconsRef.current.children),
                { opacity: 0, y: 50 },
                {
                    opacity: 1,
                    y: 0,
                    duration: 0.8,
                    stagger: 0.2,
                    ease: 'power3.out',
                    scrollTrigger: {
                        trigger: sectionElement,
                        start: 'top 80%',
                    },
                }
            );
        }

        // Animate images
        if (imagesRef.current) {
            gsap.fromTo(
                Array.from(imagesRef.current.children),
                { opacity: 0, scale: 0.9 },
                {
                    opacity: 1,
                    scale: 1,
                    duration: 1,
                    delay: 0.5,
                    stagger: 0.3,
                    ease: 'power3.out',
                    scrollTrigger: {
                        trigger: sectionElement,
                        start: 'top 80%',
                    },
                }
            );
        }
    }, []);

    return (
        <section className="relative text-white" ref={sectionRef}>
            <div
                style={{
                    background: 'radial-gradient(circle, rgba(98,60,149,0.499) 0%, rgba(98,60,149,0.502) 50%, rgba(98,60,149,0) 100%)',
                }}
                className="h-full opacity-30 z-10 w-full absolute"
            ></div>
            <div
                style={{
                    backgroundImage: `url(${color2})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                }}
            >
                <div className="container mx-auto px-4 relative z-40 flex flex-row max-lg:flex-col items-center justify-between">
                    <div className="min-lg:w-1/2 max-md:flex max-md:flex-col items-center pt-12 w-full">
                        <h2 className="text-3xl md:text-4xl font-bold mb-4" ref={headingRef}>
                            Join our Community
                        </h2>
                        <button
                            ref={buttonRef}
                            className="relative flex items-center justify-center px-6 py-3 bg-gray-100 rounded-2xl shadow-neumorphism hover:shadow-neumorphism-hover transition-shadow duration-300"
                        >
                            <span className="flex items-center justify-center w-8 h-8 bg-purple-600 rounded-full text-white mr-4">
                                <img src={tg} alt="Telegram" />
                            </span>
                            <span className="text-purple-600 font-semibold">Join our Telegram</span>
                        </button>

                        {/* Social Icons */}
                        <div className="flex space-x-6 mt-12 text-3xl" ref={iconsRef}>
                            <span className="cursor-pointer hover:text-purple-300">
                                <img style={{ filter: 'brightness(0) invert(1)' }} className="h-8 w-8" src={x} alt="X" />
                            </span>
                            <span className="cursor-pointer hover:text-purple-300">
                                <img style={{ filter: 'brightness(0) invert(1)' }} className="h-8 w-8" src={discord} alt="Discord" />
                            </span>
                            <span className="cursor-pointer hover:text-purple-300">
                                <img style={{ filter: 'brightness(0) invert(1)' }} className="h-8 w-8" src={tiktok} alt="TikTok" />
                            </span>
                            <span className="cursor-pointer hover:text-purple-300">
                                <img style={{ filter: 'brightness(0) invert(1)' }} className="h-8 w-8" src={instagram} alt="Instagram" />
                            </span>
                        </div>
                    </div>

                    {/* Right Section */}
                    <div
                        className="max-lg:w-1/2 w-full h-96 md:mt-0 flex flex-row max-lg:flex-col justify-center"
                        ref={imagesRef}
                    >
                        <img className="mr-4" src={tg2} alt="Telegram Illustration 1" />
                        <img src={tgillustration} alt="Telegram Illustration 2" />
                    </div>
                </div>
            </div>
        </section>
    );
};

export default JoinCommunitySection;
