import React, { useEffect, useRef } from 'react';
import { gsap } from 'gsap';
import sec5 from '../assets/5.mp4';
import dextool from '../assets/dextools.svg';
import uniswap from '../assets/uniswap.svg';
import cmc from '../assets/cmc.svg';
import gecko from '../assets/gecko.svg';

const TokenSection: React.FC = () => {
    const sectionRef = useRef<HTMLDivElement>(null);
    const textRef = useRef<HTMLDivElement>(null);
    const imageRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const sectionElement = sectionRef.current;

        if (!sectionElement) return;

        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    // Animate text and image
                    gsap.fromTo(
                        textRef.current,
                        { opacity: 0, x: -50 },
                        { opacity: 1, x: 0, duration: 1, ease: 'power3.out' }
                    );
                    gsap.fromTo(
                        imageRef.current,
                        { opacity: 0, x: 50 },
                        { opacity: 1, x: 0, duration: 1, delay: 0.5, ease: 'power3.out' }
                    );

                    observer.unobserve(sectionElement); // Stop observing after animation
                }
            },
            {
                threshold: 0.3, // Trigger animation when 30% of the section is visible
            }
        );

        observer.observe(sectionElement);

        return () => observer.disconnect(); // Cleanup observer
    }, []);

    return (
        <section ref={sectionRef} className="relative" id="token">
            <div
                style={{
                    background:
                        'radial-gradient(circle, rgba(98,60,149,0.499) 0%, rgba(98,60,149,0.502) 50%, rgba(98,60,149,0) 100%)',
                }}
                className="h-full opacity-30 z-10 w-full absolute"
            ></div>
            <div className="container py-32 relative mx-auto px-4 flex flex-col md:flex-row">
                {/* Text Section */}
                <div ref={textRef} className="md:w-1/2 mb-8 md:mb-0">
                    <h2 className="text-3xl md:text-4xl font-bold mb-12">
                        Kariru Token Fueling Your Financial Freedom
                    </h2>
                    <div className="flex space-x-24 mb-12">
                        <div className="text-center">
                            <p className="text-2xl font-bold">100M</p>
                            <p className="text-gray-600">Supply</p>
                        </div>
                        <div className="text-center">
                            <p className="text-2xl font-bold">$X.XX</p>
                            <p className="text-gray-600">Initial Price</p>
                        </div>
                        <div className="text-center">
                            <p className="text-2xl font-bold">Ethereum</p>
                            <p className="text-gray-600">Network</p>
                        </div>
                    </div>
                    {/* Icons */}
                    <div className="flex space-x-12">
                        <a href="#" className="text-purple-600 text-3xl">
                            <img src={cmc} alt="CMC" className="w-12 h-12" />
                        </a>
                        <a href="#" className="text-purple-600 text-3xl">
                            <img src={gecko} alt="Gecko" className="w-12 h-12" />
                        </a>
                        <a href="#" className="text-purple-600 text-3xl">
                            <img src={dextool} alt="Dextool" className="w-12 h-12" />
                        </a>
                        <a href="#" className="text-purple-600 text-3xl">
                            <img src={uniswap} alt="Uniswap" className="w-12 h-12" />
                        </a>
                    </div>
                </div>

                {/* Large $KRU Text */}
                <div className="absolute max-md:relative max-lg:top-[10%] z-20 top-[14%] max-lg:left-[2%] right-[30%]">
                    <h1
                        className="text-4xl md:text-6xl lg:text-8xl xl:text-9xl font-bold text-transparent bg-clip-text bg-gradient-to-b from-purple-300 to-purple-200/0"
                        style={{
                            WebkitMaskImage:
                                '-webkit-gradient(linear, 22% 0%, 100% 0%, from(rgb(0, 0, 0)), to(rgba(0, 0, 0, 0)))',
                        }}
                    >
                        $KRU
                    </h1>
                </div>

                {/* Image Section */}
                <div ref={imageRef} className="max-lg:w-full z-0 flex justify-end">
                    <video
                        autoPlay
                        loop
                        muted
                        playsInline
                        preload="auto"
                        className="rounded-lg"
                    >
                        <source src={sec5} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                </div>
            </div>
        </section>
    );
};

export default TokenSection;
